import React from "react"
import PropTypes from "prop-types"
import {
  ExampleArrow,
  ExampleHoverBackground,
  ExampleScreenshot,
} from "@components/resources/example-grid"
import Heading from "@components/elements/heading"
import Anchor from "@components/elements/anchor"
import Paragraph from "@components/elements/paragraph"
import Grid from "@components/elements/grid"
import Flex from "@components/elements/flex"
import Div from "@components/elements/div"
import styled from "styled-components"
import { Link } from "gatsby"
import Image from "@components/image"

const DashboardExampleScreenshotWrapper = styled(Link)`
  padding: 2rem;
  background: #fafaff !important;
  border-radius: 1rem;
`

const DashboardExampleCategoryGrid = ({ examples, slugPrefix }) => {
  return (
    <Flex margin="0 auto">
      <Grid as="ul" gap="4rem" margin="0" columns="1fr">
        {examples.map(({ node: category }, i) => {
          return (
            <Div as="li" margin="0" key={`${category.id}-${i}`}>
              <Grid
                gap="2rem"
                alignItems="center"
                columns="1fr 1fr"
                columnsMd="1fr"
              >
                <DashboardExampleScreenshotWrapper
                  to={slugPrefix + "/" + category.slug}
                >
                  <ExampleScreenshot>
                    {category.image && (
                      <Image
                        file={category.image}
                        alt={`${category.name} Dashboard Examples`}
                      />
                    )}
                    <ExampleHoverBackground radius={"0.5rem"}>
                      <ExampleArrow />
                    </ExampleHoverBackground>
                  </ExampleScreenshot>
                </DashboardExampleScreenshotWrapper>
                <div>
                  <Heading
                    margin="0 0 2rem"
                    as="h3"
                    fontSize="1.5rem"
                    tabletScale={0.9}
                    mobileScale={0.8}
                  >
                    <Anchor
                      color="inherit"
                      arrow
                      link={slugPrefix + "/" + category.slug}
                    >
                      {category.name}
                    </Anchor>
                  </Heading>
                  <Paragraph fontSize="1.2rem">{category.summary}</Paragraph>
                </div>
              </Grid>
            </Div>
          )
        })}
      </Grid>
    </Flex>
  )
}

DashboardExampleCategoryGrid.propTypes = {
  examples: PropTypes.arrayOf(PropTypes.object).isRequired,
  slugPrefix: PropTypes.string.isRequired,
}

DashboardExampleCategoryGrid.defaultProps = {
  examples: [],
  slugPrefix: "",
}

export default DashboardExampleCategoryGrid
