import React from "react"
import Layout from "@components/layout"
import { graphql } from "gatsby"
import ResourceHeader from "@components/resources/header"
import Container from "@components/layout/container"
import Html from "@components/html"
import PropTypes from "prop-types"
import DashboardExampleCategoryGrid from "@components/dashboard-examples/dashboard-example-category-grid"
import Flex from "@components/elements/flex"
import theme from "@styles/theme"
import { ResourceWrapper } from "@components/resources/resource-wrapper"
import { NeutralCTA } from "@components/cta/eop/neutral"

const DashboardExamples = ({ data, pageContext }) => {
  const home = data.home
  const categories = data.categories.edges
  const image = home.image
  const imageUrl = image?.cdn

  return (
    <Layout
      image={imageUrl}
      fullWidth={true}
      canonical={pageContext.slug}
      seo={home.seo}
      body={home.body}
      date_updated={home.date_updated}
    >
      <ResourceWrapper
        gap="6rem"
        margin="0 0 6rem"
        marginMd="0 0 4rem"
        marginSm="0 0 2rem"
      >
        <ResourceHeader
          name={home.name}
          alt={"Dashboard Examples and Templates"}
          subtitle={home.summary}
          image={image}
          shadow={false}
          categories={categories}
          newCta
          links={[
            { text: "Resources", url: "/resources" },
            {
              text: "Dashboard Examples",
            },
          ]}
        />
        <Container text>
          <Flex fontSize={theme.defaults.fontSize.p} gap="2rem">
            <Html
              inherit
              parseElements
              html={home.body}
              images={home.body_images}
              cta
            />
          </Flex>
        </Container>
        {home.body2 && (
          <Container text>
            <Flex fontSize={theme.defaults.fontSize.p} gap="2rem">
              <Html
                inherit
                parseElements
                html={home.body2}
                images={home.body2_images}
                cta
              />
            </Flex>
          </Container>
        )}
        {home.body3 && (
          <Container text>
            <Flex fontSize={theme.defaults.fontSize.p} gap="2rem">
              <Html
                inherit
                parseElements
                html={home.body3}
                images={home.body3_images}
                cta
              />
            </Flex>
          </Container>
        )}
        {home.show_related && (
          <Container>
            <DashboardExampleCategoryGrid
              slugPrefix={pageContext.slug}
              examples={categories}
            />
          </Container>
        )}
      </ResourceWrapper>
      <NeutralCTA />
    </Layout>
  )
}

DashboardExamples.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default DashboardExamples

export const pageQuery = graphql`
  query DashboardExamplesPageTemplateQuery($limit: Int!) {
    home: dashboardExamplesHome {
      id
      image {
        id
        title
        cdn
        placeholder
      }
      body
      body2
      body3
      body_images {
        cdn
        placeholder
        directus_id
        id
        title
      }
      body2_images {
        cdn
        placeholder
        id
        title
        directus_id
      }
      body3_images {
        cdn
        placeholder
        id
        title
        directus_id
      }
      title
      name
      summary
      date_updated
      seo {
        ...seoData
      }
      show_related
    }
    categories: allDashboardExamplesCategories(
      limit: $limit
      sort: { fields: sort, order: ASC }
      filter: { status: { eq: "published" } }
    ) {
      edges {
        node {
          image {
            title
            cdn
            placeholder
          }
          title
          name
          slug
          summary
        }
      }
    }
  }
`
